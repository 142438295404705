import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Pagination } from "src/app/core/dtos";
import { BaseService } from "src/app/core/services/base.service";
import { AddIssuingEntityFundsCommand } from "src/app/dtos/addIssuingEntityFundsCommand";
import { FundsMovementsHistoryListDTO } from "src/app/dtos/fundsMovementsHistoryListDTO";
import { InactivateIssuingEntityFundsCommand } from "src/app/dtos/inactivateIssuingEntityFundsCommand";
import { IssuingEntityBenefitBalanceDTO } from "src/app/dtos/issuingEntityBenefitBalanceDTO";
import { IssuingEntityDTO } from "src/app/dtos/issuingEntityDTO";
import { IssuingEntityListDTO } from "src/app/dtos/issuingEntityListDTO";
import { LoadIssuingEntityFundsCommand } from "src/app/dtos/loadIssuingEntityFundsCommand";
import { LookupValueDTO } from "src/app/dtos/lookupValueDTO";
import { UnloadIssuingEntityFundsCommand } from "src/app/dtos/unloadIssuingEntityFundsCommand";

@Injectable({
  providedIn: "root",
})
export class IssuingEntitiesService extends BaseService {
  private balance = new BehaviorSubject(false);
  getBalance = this.balance.asObservable();

  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("IssuingEntity");
  }

  setBalance(balance: boolean) {
    this.balance.next(balance);
  }

  getAll(
    filter: string,
    active: string,
    type: string,
    pageNumber: number = 1,
    sortColumn: string = "date",
    sortDirection: number = 1,
    pageSize
  ): Observable<Pagination<IssuingEntityListDTO>> {
    let params = new HttpParams();
    if (filter) {
      params = params.append("filter", filter);
    }
    if (active) {
      params = params.append("active", active);
    }
    if (type) {
      params = params.append("type", type);
    }
    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize.toString());
    }
    params = params.append("page", pageNumber.toString());
    return this.http.get<Pagination<IssuingEntityListDTO>>(this.buildUrl(""), {
      params,
    });
  }

  getOperations(
    filter: string,
    issuingEntityId: number,
    startDate: string,
    endDate: string,
    operation: string,
    program: string,
    pageNumber: number = 1,
    sortColumn: string = "movementDate",
    sortDirection: number = -1,
    pageSize
  ): Observable<Pagination<FundsMovementsHistoryListDTO>> {
    let params = new HttpParams();
    if (filter) {
      params = params.append("filter", filter);
    }
    if (startDate) {
      params = params.append("fromDate", startDate);
    }
    if (endDate) {
      params = params.append("toDate", endDate);
    }
    if (operation) {
      params = params.append("operationType", operation);
    }
    if (program) {
      params = params.append("benefitCode", program);
    }

    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize.toString());
    }
    params = params.append("page", pageNumber.toString());
    return this.http.get<Pagination<FundsMovementsHistoryListDTO>>(
      this.buildUrl(`/${issuingEntityId}/Funds`),
      {
        params,
      }
    );
  }

  activate(id: number): Observable<IssuingEntityDTO> {
    return this.http.put<IssuingEntityDTO>(
      this.buildUrl(`/${id}/Activate`),
      {}
    );
  }

  deactivate(id: number): Observable<IssuingEntityDTO> {
    return this.http.put<IssuingEntityDTO>(
      this.buildUrl(`/${id}/Deactivate`),
      {}
    );
  }

  lookUp(filter: string): Observable<LookupValueDTO[]> {
    let params = new HttpParams();

    return this.http.get<LookupValueDTO[]>(this.buildUrl(`/LookUp/${filter}`), {
      params,
    });
  }

  addBalance(dto: AddIssuingEntityFundsCommand): Observable<any> {
    return this.http.post(
      this.buildUrl(`/${dto.issuingEntityId}/Funds/Add`),
      dto
    );
  }

  loadBalance(dto: LoadIssuingEntityFundsCommand): Observable<any> {
    return this.http.post(
      this.buildUrl(`/${dto.issuingEntityId}/Funds/Load`),
      dto
    );
  }
  unloadBalance(dto: UnloadIssuingEntityFundsCommand): Observable<any> {
    return this.http.post(
      this.buildUrl(`/${dto.issuingEntityId}/Funds/Unload`),
      dto
    );
  }
  inactivateBenefit(dto: InactivateIssuingEntityFundsCommand): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: dto,
    };
    return this.http.delete(
      this.buildUrl(`/${dto.issuingEntityId}/Funds/Inactivate`),
      httpOptions
    );
  }

  getBenefits(id: number): Observable<IssuingEntityBenefitBalanceDTO[]> {
    return this.http.get<IssuingEntityBenefitBalanceDTO[]>(
      this.buildUrl(`/${id}/Benefits`),
      {}
    );
  }

  exportList(
    filter: string,
    active: string,
    type: string,
    pageNumber: number = 1,
    sortColumn: string = "name",
    sortDirection: number = 1,
    pageSize
  ) {
    let params = new HttpParams();
    if (filter) {
      params = params.append("filter", filter);
    }
    if (active) {
      params = params.append("active", active);
    }
    if (type) {
      params = params.append("type", type);
    }
    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize.toString());
    }
    params = params.append("page", pageNumber.toString());

    return this.http.get(this.buildUrl(`/ExportList`), {
      params,
      responseType: "blob",
    });
  }
}
